import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'
import Tree from 'components/button/Tree'
import Button from 'components/button/Button'
import Loader from 'components/status/Loader'
import Alert from 'components/alert/Alert'
import Toast, { showToast } from 'components/alert/Toast'
import WarningLabel from 'components/label/WarningLabel'

import CateringForm, { FormValues, MAX_HOUR } from './CateringForm'

import useI18n from 'i18n/useI18n'

import api from './api'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'

import Logger from 'utils/Logger'
import { breakpoints } from 'utils/breakpoints'
import { formatDate, formatInstantRequest } from './utils'
import { isBefore, differenceInBusinessDays } from 'date-fns'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
}

type FormMode = 'DETAIL' | 'UPDATE'

const CateringDetailScreen = ({ id }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const site = useReducer(SiteStore.store, (s) => s.site)
  const sites = useReducer(SiteStore.store, (s) => s.sites)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [detail, setDetail] = React.useState<InstantDetailed>()
  const [formMode, setFormMode] = React.useState<FormMode>('DETAIL')
  const [modifiedValues, setModifiedValues] = React.useState<Partial<FormValues>>()

  const now = new Date()

  const instantSite = React.useMemo(() => sites.find((s) => s.id.toString() === detail?.siteId), [
    sites,
    detail?.siteId,
  ])

  const instantStatus = React.useMemo(() => {
    if (!!detail) {
      if (detail.status === 'CANCELED') {
        return 'canceled'
      }
      const instantDate = formatDate(detail.date, detail.startingTime)
      if (isBefore(instantDate, now)) {
        return 'past'
      }
      const diffDays = differenceInBusinessDays(instantDate, now)
      if (
        diffDays < detail.instantType.timeAheadDay ||
        (diffDays === detail.instantType.timeAheadDay && now.getHours() >= MAX_HOUR)
      ) {
        return 'near'
      }
      return 'editable'
    }
  }, [detail, now])

  const isFormInvalid = React.useMemo(() => {
    if (!!detail && !!modifiedValues) {
      const {
        instantType,
        numberGuests = detail.numberGuests,
        additionalArticles,
        costCenter,
        costCenterId,
        comment,
      } = modifiedValues

      const isIncorrectField =
        !instantType ||
        numberGuests < instantType.minUser ||
        (!!instantType.maxUser && numberGuests > instantType.maxUser)

      const isNotModified =
        instantType?.id === detail.instantType.id &&
        numberGuests === detail.numberGuests &&
        additionalArticles === detail.additionalArticles &&
        (!!costCenter?.id ? costCenter.id === detail.costCenter?.id : costCenterId === detail.customCostCenter) &&
        comment === detail.comment

      return isIncorrectField || isNotModified
    }
    return true
  }, [detail, modifiedValues])

  React.useEffect(() => {
    if (!!site && !!instantSite && site.id !== instantSite.id) {
      // On force le changement de site si le Catering n'est pas activé sur le site actuel de l'utilisateur
      const isCateringActivated = site.functionalities.find((f) => f.type === 'CATERING' && f.activated)
      if (!isCateringActivated) {
        SiteStore.actions.setSite(instantSite)
        showToast({ label: i18n.t('screens.meeting.catering.detail.newSite', { site: instantSite.name }), icon: 'pin' })
      }
    }
  }, [site, instantSite])

  React.useEffect(() => {
    if (!!id) {
      setStatus('loading')

      analytics.screen({
        screen_feature: values.screens.catering,
        screen_name: values.screens.cateringDetail,
        screen_object_id: id,
      })

      api
        .getCateringInstantById(id)
        .then((detail) => {
          setDetail(detail)
          setStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }, [id])

  const modifyInstant = () => {
    analytics.event({
      event_feature: values.eventName.catering,
      event_action: values.actions.editBooking,
      event_object_id: id,
    })

    Alert.close()
    if (!!instantSite && !!detail && modifiedValues) {
      api
        .updateCateringInstant(id, formatInstantRequest(instantSite.id, detail, { ...modifiedValues }))
        .then((updatedDetail) => {
          setDetail(updatedDetail)
          Alert.open({
            title: i18n.t('common.confirmation'),
            description: i18n.t('screens.meeting.catering.detail.alert.modifySuccess'),
          })
          setFormMode('DETAIL')
        })
        .catch((err) => {
          Logger.error(err)
          Alert.open({
            title: i18n.t('common.error'),
            description: i18n.t('screens.meeting.catering.detail.alert.modifyFail'),
          })
        })
    }
  }

  const deleteInstant = () => {
    analytics.event({
      event_feature: values.eventName.catering,
      event_action: values.actions.deleteBooking,
      event_object_id: id,
    })

    Alert.close()
    api
      .deleteCateringInstant(id)
      .then((deletedInstant) => {
        setDetail(deletedInstant)
        Alert.open({
          title: i18n.t('common.confirmation'),
          description: i18n.t('screens.meeting.catering.detail.alert.deleteSuccess'),
        })
      })
      .catch((err) => {
        Logger.error(err)
        Alert.open({
          title: i18n.t('common.error'),
          description: i18n.t('screens.meeting.catering.detail.alert.deleteFail'),
        })
      })
  }

  const openConfirmation = (type: 'modify' | 'delete') =>
    Alert.open({
      title: i18n.t('common.warning'),
      description: i18n.t(`screens.meeting.catering.detail.alert.${type}Confirm`),
      buttons: [
        { label: i18n.t('common.cancel'), onClick: Alert.close, style: 'secondary' },
        {
          label: i18n.t(`common.${type}`),
          onClick: type === 'modify' ? modifyInstant : deleteInstant,
        },
      ],
    })

  return (
    <ScreenContainer>
      <TitleHelmet title={i18n.t('screens.meeting.catering.title')} />

      <Main>
        <MainContainer>
          <Tree
            previousPages={[{ url: 'meeting/catering', title: 'screens.meeting.title_breadcrumb' }]}
            currentPageTitle={i18n.t('screens.meeting.catering.detail.title')}
          />
          {status === 'loading' ? (
            <Loader />
          ) : status === 'error' || !detail || !instantStatus ? (
            <ErrorContainer>
              <Error>{i18n.t('common.errorDescription')}</Error>
            </ErrorContainer>
          ) : (
            <>
              {instantStatus !== 'editable' && (
                <WarningContainer>
                  <WarningLabel
                    icon="warning"
                    title={i18n.t(`screens.meeting.catering.detail.warning.${instantStatus}`, {
                      count: detail.instantType.timeAheadDay * 24, // timeAheadDay est un nombre de jours, on veut afficher le nombre d'heures
                    })}
                    description={i18n.t(
                      `screens.meeting.catering.detail.warning.${
                        instantStatus === 'canceled' ? 'canceledDescription' : 'description'
                      }`
                    )}
                    color={theme.colors.mauve}
                  />
                </WarningContainer>
              )}
              <CateringDetailContainer editable={instantStatus !== 'editable'}>
                <ContentContainer>
                  <CateringForm
                    bookedInstant={detail}
                    mode={formMode}
                    onValuesChange={(modifiedFields) => setModifiedValues({ ...modifiedValues, ...modifiedFields })}
                    instantStatus={instantStatus}
                  />
                </ContentContainer>
                <ButtonsContainer>
                  <>
                    {formMode === 'UPDATE' ? (
                      <Button
                        label={i18n.t('common.validate')}
                        onClick={() => !isFormInvalid && openConfirmation('modify')}
                        disabled={instantStatus !== 'editable' || isFormInvalid}
                        color={theme.colors.accent}
                      />
                    ) : (
                      <Button
                        label={i18n.t('common.modify')}
                        onClick={() => setFormMode('UPDATE')}
                        disabled={instantStatus !== 'editable'}
                      />
                    )}
                  </>
                  <>
                    {formMode === 'UPDATE' ? (
                      <Button
                        label={i18n.t('common.cancel')}
                        onClick={() => {
                          setFormMode('DETAIL')
                          setModifiedValues(undefined)
                        }}
                        disabled={instantStatus !== 'editable'}
                        style="secondary"
                      />
                    ) : (
                      <Button
                        label={i18n.t('common.delete')}
                        onClick={() => openConfirmation('delete')}
                        disabled={instantStatus !== 'editable'}
                        style="danger"
                      />
                    )}
                  </>
                </ButtonsContainer>
              </CateringDetailContainer>
            </>
          )}

          <Toast />
        </MainContainer>
      </Main>
    </ScreenContainer>
  )
}

export default CateringDetailScreen

const ScreenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: calc(100vh - ${HEADER_HEIGHT + 80}px);

  @media only screen and (max-width: ${breakpoints.phone}px) {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
  }
`

const MainContainer = styled.div`
  padding: 40px 76px;
  display: flex;
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 40px 35px;
  }
`

const Text = styled('p')`
  margin: 0px;
  padding: 0px;
`

const WarningContainer = styled.div`
  margin-top: 24px;
  align-self: center;
  @media only screen and (min-width: ${breakpoints.medium}px) {
    align-items: flex-start;
  }
`

const CateringDetailContainer = styled.div<{ editable?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${(props) => (props.editable ? 20 : 50)}px;
  width: 100%;
  @media only screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  min-width: 60%;
`

// BUTTONS

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: sticky;
  align-self: flex-start;
  top: 40px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: row;
    align-self: center;
  }
`

// ERROR

const ErrorContainer = styled('div')`
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  margin-top: 50px;
  flex: 1;
`

const Error = styled(Text)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
`
