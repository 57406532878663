import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

interface Props {
  title?: string
  description: string
  color?: string
  icon?: IconName
}

const WarningLabel = ({ title, description, color, icon }: Props) => {
  const [theme] = useTheme()

  return (
    <WarningContainer color={!!color ? `${color}22` : theme.colors.warningBackground}>
      <Icon name={icon || 'warning_round'} size={24} color={color || theme.colors.warning} />

      <TextContainer>
        {!!title && <WarningText isTitle>{title}</WarningText>}
        <WarningText>{description}</WarningText>
      </TextContainer>
    </WarningContainer>
  )
}

export default WarningLabel

const WarningContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin: 16px 0;
  padding: 20px;
  width: 100%;
`

const TextContainer = styled.div`
  flex: 1;
  flex-direction: column;
`

const WarningText = styled.p<{ isTitle?: boolean }>`
  ${(props) => (props.isTitle ? props.theme.fonts.bodyBold : props.theme.fonts.subtitle)};
  margin: 0 10px ${(props) => (props.isTitle ? 4 : 0)}px;
`
