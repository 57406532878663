import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Main from 'components/main/Main'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Tree from 'components/button/Tree'
import Loader from 'components/status/Loader'
import Icon from 'components/icons/Icon'
import Alert from 'components/alert/Alert'
import Modal from 'components/modal/Modal'
import WarningLabel from 'components/label/WarningLabel'
import Button from 'components/button/Button'

import SlotsList from './SlotsList'
import RegisterEmailInput from './RegisterEmailInput'
import AnimationDetailContent from './AnimationDetailContent'
import RegisterDrawerContent from './RegisterDrawerContent'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as UserStore from 'store/user/user'
import * as RegisterStore from './store'

import useNavigation from 'core/src/layout/useNavigation'

import api from './api'

import {
  getDateString,
  fetchAnimations,
  fetchReservations,
  getRestrictedCategory,
  isAnimationFull,
  isMultipleDays,
} from './utils'
import Logger from 'utils/Logger'
import { breakpoints } from 'utils/breakpoints'
import { endOfDay, isBefore } from 'date-fns'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
  bookingId?: string
}

const RegisterDetailScreen = ({ id, bookingId }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const navigation = useNavigation()

  const site = useReducer(SiteStore.store, (s) => s.site)
  const userEmail = useReducer(UserStore.store, (s) => s.user)?.mail
  const allReservations = useReducer(RegisterStore.store, (s) => s.reservations)
  const animationUser = useReducer(RegisterStore.store, (s) => s.animationUser)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [mode, setMode] = React.useState<RegisterDetailMode>(!!bookingId ? 'DETAIL' : 'BOOK')
  const [disableActions, setDisableActions] = React.useState(false)
  const [detail, setDetail] = React.useState<AnimationDetail>()
  const [subscribed, setSubscribed] = React.useState(false)

  // Inscription d'un autre collaborateur
  const [isDelegated, setIsDelegated] = React.useState(false)
  const [emailData, setEmailData] = React.useState<AnimationEmailUser | undefined>()

  const isFull = React.useMemo(() => !!detail && isAnimationFull(detail), [detail])

  // Liste des autres réservations de l'animation
  const alreadyBooked = React.useMemo(
    () =>
      isDelegated
        ? []
        : allReservations
            .filter((r) => r.animationId === id && r.status === 'BOOKED')
            .sort((a, b) => a.startDate.localeCompare(b.startDate)),
    [id, allReservations, isDelegated]
  )

  const reservation = React.useMemo(() => alreadyBooked.find((r) => r.id === bookingId), [alreadyBooked, bookingId])

  const restrictedCategory = React.useMemo(
    () => getRestrictedCategory(detail, !!emailData && isDelegated ? emailData.data : animationUser),
    [detail, animationUser, emailData, isDelegated]
  )

  const multipleDays = React.useMemo(() => !!detail && isMultipleDays(detail), [detail])

  const reservationTimeLabel = React.useMemo(
    () =>
      !!reservation
        ? getDateString(i18n, reservation.startDate) +
          ' • ' +
          i18n.t('screens.register.detail.timeSlot', {
            start: new Date(reservation.startDate),
            end: new Date(reservation.endDate),
          })
        : '',
    [reservation]
  )

  React.useEffect(() => {
    if (!!userEmail) {
      api.getUserInfo(userEmail).then(RegisterStore.actions.setAnimationUser).catch(Logger.error)
    }
  }, [userEmail])

  React.useEffect(() => {
    fetchAnimations(site?.id)
    fetchReservations()
  }, [])

  React.useEffect(() => {
    if (!bookingId) {
      analytics.screen({
        screen_feature: values.screens.register,
        screen_name: values.screens.registerBooking,
        screen_object_id: id,
      })
      setMode('BOOK')
    } else {
      analytics.screen({
        screen_feature: values.screens.register,
        screen_name: values.screens.registerDetail,
        screen_object_id: bookingId,
      })
    }
    if (allReservations.length === 0) {
      // Si on arrive directement sur le détail (depuis un lien), il faut récupérer la liste des réservations
      fetchReservations()
    }
  }, [bookingId])

  React.useEffect(() => {
    if (!!reservation && reservation.status !== 'BOOKED') {
      // La réservation a été annulée
      setStatus('error')
      navigation.push('/register')
      Alert.open({
        title: i18n.t('common.error'),
        description: i18n.t(`screens.register.detail.statusError.${reservation.status || 'CANCELLED'}`),
      })
    }
  }, [reservation])

  React.useEffect(() => {
    if (!!id) {
      setStatus('loading')
      api
        .getDetail(id)
        .then((detail) => {
          if (detail.status !== 'CREATED') {
            // La page de détail et la réservation ne devraient pas être accesibles
            setStatus('error')
            navigation.push('/register')
            Alert.open({
              title: i18n.t('common.error'),
              description: i18n.t(`screens.register.detail.statusError.${detail.status || 'CANCELLED'}`),
            })
          } else {
            setDetail(detail)
            setStatus('ok')
          }
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }, [id])

  React.useEffect(() => {
    if (!!id && !!userEmail) {
      api
        .getAlert(id)
        .then(({ alertList }) => setSubscribed(alertList.includes(userEmail)))
        .catch(Logger.error)
    }
  }, [id, userEmail])

  const errorAlert = (err: any) => {
    Logger.error(err)
    Alert.open({ title: i18n.t('common.error'), description: i18n.t('common.errorDescription') })
  }

  const cancelReservation = (reservation: Booking) => {
    if (!disableActions) {
      setDisableActions(true)
      if (!!bookingId && !!reservation && !!reservation.slotId) {
        Alert.open({
          title: i18n.t('screens.register.detail.removeBooking'),
          children: <AnimationDetailContent reservation={reservation} small />,
          buttons: [
            {
              label: i18n.t('common.cancel'),
              onClick: () => {
                Alert.close()
                setDisableActions(false)
              },
              style: 'secondary',
            },
            {
              label: i18n.t('common.delete'),
              onClick: () => {
                analytics.event({
                  event_feature: values.eventName.register,
                  event_action: values.actions.deleteBooking,
                  event_object_id: bookingId,
                })
                api
                  .cancel(id, reservation.slotId!, bookingId)
                  .then(() => {
                    Alert.close()
                    Alert.open({
                      title: i18n.t('common.success'),
                      description: i18n.t('screens.myReservations.deleteSucceed'),
                    })
                    navigation.push('/register')
                  })
                  .catch((err) => {
                    Logger.error(err)
                    Alert.close()
                    Alert.open({ title: i18n.t('common.error'), description: i18n.t('common.errorDescription') })
                  })
                  .finally(() => setDisableActions(false))
              },
            },
          ],
        })
      } else {
        errorAlert(`bookingId: ${bookingId}, slotId: ${reservation?.slotId}`)
        setDisableActions(false)
      }
    }
  }

  const openQrCode = (booking: Booking) => {
    Modal.open({
      Content: () => (
        <RegisterDrawerContent
          type={detail?.invitationType || 'QR_CODE'}
          cardType={detail?.invitationCardType}
          booking={booking}
        />
      ),
    })
  }

  const subscribe = () =>
    api
      .subscribe(id)
      .then(() => setSubscribed(true))
      .catch(errorAlert)

  const unsubscribe = () =>
    api
      .unsubscribe(id)
      .then(() => setSubscribed(false))
      .catch(errorAlert)

  const screenTitle = isDelegated
    ? i18n.t('screens.register.detail.collaborator.bookFor')
    : mode === 'BOOK'
    ? i18n.t('screens.register.detail.title')
    : mode === 'DETAIL'
    ? i18n.t('screens.myReservations.detailTitle')
    : i18n.t('screens.myReservations.modifyTitle')

  const renderAlreadyBooked = (prevBooking: Booking) => (
    <AlreadyBookedItem key={prevBooking.id}>
      <AlreadyBookedLabel>{i18n.t('screens.register.detail.alreadyBooked')}</AlreadyBookedLabel>
      <AlreadyBookedValue onClick={() => navigation.push(`/register/${id}/${prevBooking.id}`)}>
        {i18n.t(`screens.register.detail.${multipleDays ? 'dateSlot' : 'timeSlot'}`, {
          start: new Date(prevBooking.startDate),
          end: new Date(prevBooking.endDate),
        })}
      </AlreadyBookedValue>
    </AlreadyBookedItem>
  )

  const renderSubscribeButton = () => {
    if (
      !!detail &&
      detail.slots.length > alreadyBooked.length &&
      (detail.multipleSlotByUser || alreadyBooked.length === 0)
    ) {
      // On n'affiche le bouton "Me prévenir" que s'il existe au moins un créneau non réservé par l'utilisateur
      // et que l'utilisateur pourrait réserver un créneau sur cette animation (non réservée, ou multiples créneaux acceptés)

      return (
        <SubscribeContainer>
          {subscribed && (
            <SubscribeDescriptionContainer>
              <SubscribeDescriptionLabel>{i18n.t('screens.register.push.description')}</SubscribeDescriptionLabel>
            </SubscribeDescriptionContainer>
          )}
          <SubscribeButton onClick={() => (subscribed ? unsubscribe() : subscribe())}>
            <Icon name={subscribed ? 'unsubscribe' : 'subscribe'} size={24} color={theme.colors.coral} />
            <SubscribeLabel>
              {i18n.t(`screens.register.push.${subscribed ? 'unsubscribe' : 'subscribe'}`)}
            </SubscribeLabel>
          </SubscribeButton>
        </SubscribeContainer>
      )
    }
  }

  const renderRestrictedWarning = () => {
    if (mode !== 'DETAIL' && !!restrictedCategory) {
      return (
        <WarningContainer>
          <WarningLabel
            icon="padlock"
            title={i18n.t(`screens.register.detail.restricted.${isDelegated ? 'collaborator' : 'user'}`)}
            description={
              isDelegated && restrictedCategory === 'unknown'
                ? i18n.t('screens.register.detail.restricted.unknown')
                : i18n.t('screens.register.detail.restricted.description', {
                    category: i18n.t(`screens.register.detail.restricted.category.${restrictedCategory}`),
                  })
            }
          />
        </WarningContainer>
      )
    }
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={screenTitle} />

        <Tree
          previousPages={
            isDelegated
              ? [
                  { url: 'register', title: 'screens.register.title' },
                  { url: `register/${id}`, title: detail?.name || '' },
                ]
              : [{ url: 'register', title: 'screens.register.title' }]
          }
          currentPageTitle={isDelegated ? i18n.t('screens.register.detail.collaborator.bookFor') : detail?.name || ''}
        />

        <ContentContainer>
          {status === 'loading' ? (
            <Loader />
          ) : status === 'error' || !detail ? (
            <ErrorContainer>
              <Error>{i18n.t('common.errorDescription')}</Error>
            </ErrorContainer>
          ) : (
            <Content>
              {isDelegated && <ScreenTitle>{i18n.t('screens.register.detail.collaborator.bookingFor')}</ScreenTitle>}

              <AnimationDetailContent detail={detail} small={isDelegated} />

              {isDelegated && <RegisterEmailInput setEmailData={setEmailData} />}

              {renderRestrictedWarning()}

              {!isDelegated && (
                <>
                  <Title>{detail.name}</Title>
                  <Resume dangerouslySetInnerHTML={{ __html: detail.description }} />
                </>
              )}

              {(!isDelegated || !!emailData) &&
                (mode !== 'DETAIL' ? (
                  <>
                    {(!restrictedCategory || detail.delegatedInvitation) && (
                      <SlotsTitleContainer>
                        {!restrictedCategory && (
                          <SlotsTitle>
                            {isFull
                              ? i18n.t('screens.register.detail.noAvailableSlot')
                              : i18n.t('screens.register.detail.bookSlot')}
                          </SlotsTitle>
                        )}
                        {!isFull && detail.delegatedInvitation && mode === 'BOOK' && !isDelegated && (
                          <Button
                            label={i18n.t('screens.register.detail.collaborator.bookFor')}
                            onClick={() => setIsDelegated(true)}
                            style="small"
                          />
                        )}
                      </SlotsTitleContainer>
                    )}

                    {mode === 'BOOK' && alreadyBooked.length > 0 && (
                      <AlreadyBookedContainer>
                        {alreadyBooked
                          .filter((a) => !multipleDays || isBefore(new Date(), endOfDay(new Date(a.endDate))))
                          .map(renderAlreadyBooked)}
                      </AlreadyBookedContainer>
                    )}

                    {isFull ? (
                      renderSubscribeButton()
                    ) : (
                      <SlotsList
                        detail={detail}
                        booked={alreadyBooked}
                        animationUser={animationUser}
                        mode={mode}
                        isDelegated={isDelegated}
                        emailData={emailData}
                      />
                    )}
                  </>
                ) : (
                  !!reservation && (
                    <SlotContainer>
                      <SlotInfo>
                        <SlotTime>{reservationTimeLabel}</SlotTime>
                      </SlotInfo>
                      <IconContainer onClick={() => openQrCode(reservation)}>
                        <Icon
                          name={detail.invitationType === 'QR_CODE' ? 'qrcode' : 'invitation'}
                          size={24}
                          color={theme.colors.primaryDark}
                          cursor="pointer"
                        />
                      </IconContainer>
                      <IconContainer onClick={() => setMode('MODIFY')}>
                        <Icon name="pencil" size={24} color={theme.colors.primaryDark} cursor="pointer" />
                      </IconContainer>
                      <IconContainer onClick={() => cancelReservation(reservation)}>
                        <Icon name="cross" size={24} color={theme.colors.primaryDark} cursor="pointer" />
                      </IconContainer>
                    </SlotContainer>
                  )
                ))}
            </Content>
          )}
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default RegisterDetailScreen

const ScreenContainer = styled('div')`
  padding: 50px 75px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints.small}px) {
    padding: 16px 0;
  }
`

const ScreenTitle = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  margin-bottom: 36px;
`

const ErrorContainer = styled('div')`
  flex: 1;
  padding: 24px;
  align-items: center;
  justify-content: center;
`

const Text = styled('p')`
  margin: 0px;
  padding: 0px;
`

const Error = styled(Text)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`

const SlotsTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 20px;
`

const SlotsTitle = styled(Text)`
  ${(props) => props.theme.fonts.h3Bold};
`

const WarningContainer = styled('div')`
  padding: 32px 0 16px;
`

// Animation already booked

const AlreadyBookedContainer = styled('div')`
  padding: 16px 0px;
  gap: 10px;
  margin: 0px;
`

const AlreadyBookedItem = styled('div')`
  padding: 12px 8px;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.transparentYellow};
`

const AlreadyBookedLabel = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryDark};
`

const AlreadyBookedValue = styled(Text)`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.raspberry};
  text-decoration: underline;
  text-decoration-color: ${(props) => props.theme.colors.raspberry};
  cursor: pointer;
`

// Subscribe to push notifications

const SubscribeContainer = styled('div')`
  padding: 20px 0px;

  @media only screen and (min-width: ${breakpoints.small}px) {
    align-items: flex-start;
  }
`

const SubscribeDescriptionContainer = styled('div')`
  padding: 12px 8px;
  margin-bottom: 12px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 122, 112, 0.15);
`

const SubscribeDescriptionLabel = styled(Text)`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`

const SubscribeButton = styled('button')`
  display: flex;
  flex-direction: row;
  padding: 9px 24px;
  align-items: center;
  justify-content: center;
  border-radius: 45px;
  border: 1px solid ${(props) => props.theme.colors.coral};
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
`

const SubscribeLabel = styled(Text)`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.coral};
  margin-left: 16px;
  justify-content: center;
`

// Slot

const SlotContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 12px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  margin-top: 32px;
`

const SlotInfo = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const SlotTime = styled(Text)<{ bold?: boolean }>`
  ${(props) => (props.bold ? props.theme.fonts.bodyBold : props.theme.fonts.subtitle)};
  color: ${(props) => props.theme.colors.primaryText};
`

const IconContainer = styled('div')`
  padding: 7px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  margin-left: 12px;
  :hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
    cursor: pointer;
  }
`

const Content = styled('div')`
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin-right: 0px;
    flex: 1;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  margin-bottom: 0;
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin: 0 0 15px;
  }
`

const Resume = styled('p')`
  padding-top: 16px;
  ${(props) => props.theme.fonts.h3};
`
