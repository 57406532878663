import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Category from 'components/label/Category'
import Icon from 'components/icons/Icon'
import Modal from 'components/modal/Modal'

import AnimationCard from './AnimationCard'

import useI18n from 'i18n/useI18n'

import { capitalize } from 'utils/stringUtils'
import { breakpoints } from 'utils/breakpoints'

interface Props {
  type: RegisterDrawerMode
  cardType?: AnimationCardType
  booking: Booking
  email?: string
}

const RegisterDrawerContent = ({ type, cardType, booking, email }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  // Affichage du QR code et infos du créneau réservé
  if (type === 'QR_CODE' || type === 'CARTON_INVITATION') {
    return <AnimationCard type={type} cardType={cardType} booking={booking} />
  }

  const slotDate = `${capitalize(
    i18n.t('screens.register.dayMonthYearShort', { date: new Date(booking.startDate) })
  )} • ${i18n.t('screens.register.detail.timeSlot', {
    start: new Date(booking.startDate),
    end: new Date(booking.endDate),
  })}`

  // Message de succès à la réservation d'un créneau
  return (
    <>
      <DrawerContent>
        <DrawerTitleContainer>
          <Icon name="check_circle" size={36} color={theme.colors.available} />
          <DrawerTitle>
            {i18n.t(
              `screens.${
                type === 'BOOK_SUCCEED' ? 'register.detail.bookResult.bookSucceed' : 'myReservations.modifySucceed'
              }`
            )}
          </DrawerTitle>
        </DrawerTitleContainer>

        {type === 'BOOK_SUCCEED' ? (
          <DrawerDescription>
            {!!email
              ? i18n.t('screens.register.detail.bookResult.collaboratorSucceed', { email })
              : i18n.t('screens.register.detail.bookResult.bookSucceedDescription')}
          </DrawerDescription>
        ) : (
          <SlotSummary>
            <ItemDate>{slotDate}</ItemDate>
            <AnimationName>{booking.animationName}</AnimationName>
            {!!booking.animationPlace && (
              <Category
                icon="compass"
                label={booking.animationPlace}
                font="link"
                iconSize={15}
                iconColor={theme.colors.iconicGrey}
              />
            )}
          </SlotSummary>
        )}
      </DrawerContent>

      <DrawerButtonContainer>
        <Button label={i18n.t('common.close')} onClick={Modal.close} width={155} />
      </DrawerButtonContainer>
    </>
  )
}

export default RegisterDrawerContent

// BOOKING RESULT DRAWER

const DrawerContent = styled('div')`
  flex-direction: column;
`

const DrawerTitleContainer = styled('div')`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`

const DrawerTitle = styled('p')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0px 0px 0px 16px;
`

const DrawerDescription = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`

const SlotSummary = styled('div')`
  margin: 16px 32px 0px 0px;
  padding: 8px;
  border-radius: 8px;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.contentBackground};

  @media only screen and (min-width: ${breakpoints.small}px) {
    min-width: 420px;
  }
`

const ItemDate = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.functionalities.register};
  font-size: 12px;
  margin: 0px 8px 0px 0px;
`

const AnimationName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 8px 0px 12px;
`

const DrawerButtonContainer = styled('div')`
  margin-top: 52px;
  min-width: 90px;

  @media only screen and (min-width: ${breakpoints.small}px) {
    align-items: center;
    justify-content: flex-end;
  }
`
